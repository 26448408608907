<template>
  <teleport to="body">
    <Dialog
      :visible="showDialog"
      :style="{ width: '450px' }"
      :header="header"
      :modal="true"
      :contentStyle="{overflow: 'visible'}"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <slot><span>Confirmation text</span></slot>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="unconfirmed"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="confirmed"
        />
      </template>
    </Dialog>
  </teleport>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";

export default {
  components: {
    Dialog,
    Button,
  },
  emits: ['confirmed','unconfirmed'],
  props: {
      showDialog: {
          type: Boolean,
          required: true,
          default: false
      },
      header: {
          type: String,
          required: false,
          default: 'Confirm'
      }
  },
  setup(_, { emit }) {
      function confirmed() {
          emit('confirmed');
      }
        function unconfirmed() {
          emit('unconfirmed');
      }
    
      return {
          confirmed,
          unconfirmed
      }
  }
};
</script>

<style scoped>
.confirmation-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
</style>